<template>
  <div class="flash-footer">
    <div class="content">
      <div class="logo-wrapper">
        <div class="logo" />

        <h3>Flash</h3>
      </div>

      <nav>
        <a href="#specialist"><h1>СПЕЦИАЛИСТ</h1></a>

        <a href="#price"><h1>ПРАЙС</h1></a>

        <a href="#equipment"><h1>АППАРАТ</h1></a>

        <a href="#faq"><h1>ОТВЕТЫ НА ВОПРОСЫ</h1></a>

        <a href="#contacts"><h1>КОНТАКТЫ</h1></a>
      </nav>
    </div>

    <div
        v-for="(author, index) in authors"
        :key="`flash-footer-authors-${ index }`"
        class="authors"
    >
      <h1 v-html="author.title" />

      <h1 class="name" v-html="author.authorName" @click="handleAuthorNameClick(author.link)" :title="author.link" />
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "flash-footer",

  data() {
    return {
      authors: [
          {
            title: "Разработка сайта:",
            authorName: "samigrella.com",
            link: "https://samigrella.com/"
          },
      ],
    }
  },

  methods: {
    handleAuthorNameClick(url) {
      window.open(url, '_blank').focus();
    },
  },
})
</script>

<style scoped lang="sass">
.flash-footer
  display: flex
  flex-direction: column
  align-items: center
  background-color: $bordo
  color: white
  margin-top: auto

  @media (min-width: $desktopScreenMinWidth)
    gap: 30px
    padding: 80px 0

  @media (max-width: $mobileScreenMaxWidth)
    gap: 50px
    padding: 60px 0

  .content
    display: flex
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      width: 1000px
      justify-content: space-between

    @media (max-width: $mobileScreenMaxWidth)
      gap: 40px
      flex-direction: column

    .logo-wrapper
      display: flex
      flex-direction: column
      gap: 4px
      align-items: center
      font-weight: 400
      font-size: 10px
      line-height: 100%

      .logo
        width: 41px
        height: 40px
        +background-image-settings()
        background-image: url("/src/assets/images/svg-icons/logo-white.svg")

    nav
      display: flex
      align-items: center
      gap: 30px
      font-weight: 400
      font-size: 20px
      line-height: 100%

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column

  .authors
    display: flex
    align-items: center
    gap: 4px
    font-weight: 400
    font-size: 15px
    line-height: 100%

    @media (min-width: $desktopScreenMinWidth)
      margin-left: -270px

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column

    .name
      color: $lightBordo

      &:hover
        text-decoration: underline
        cursor: pointer
</style>
<template>
  <button class="flash-button" @click="handleButtonClick">
    <h1 v-html="title" />

    <img v-if="srcIcon" :src="srcIcon" alt="">
  </button>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "flash-button",

  props: {
    title: {
      type: String,
      default() {
        return null
      },
    },

    srcIcon: {
      type: String,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      whatsappNumber: '+79878970773',
    }
  },

  computed: {
    imageUrl() {
      if (!this.srcIcon) {
        return "none"
      }

      return `url(${ this.srcIcon })`
    },
  },

  methods: {
    handleButtonClick() {
      const url = `//api.whatsapp.com/send?phone=${ this.whatsappNumber }&text=${ this.getWelcomeText() }!%20На%20какое%20время%20есть%20ближайшая%20запись?`

      window.open(url, '_blank').focus();
    },

    getCurrentHours() {
      return new Date().getUTCHours() + 3
    },

    getWelcomeText() {
      const hours = this.getCurrentHours()

      if (hours >= 5 && hours < 12) {
        return "Доброе утро"
      }

      if (hours >= 12 && hours < 17) {
        return "Добрый день"
      }

      if (hours >= 17 && hours < 22) {
        return "Добрый вечер"
      }

      return "Доброй ночи"
    },
  },
})
</script>

<style scoped lang="sass">
.flash-button
  font-weight: 400
  font-size: 17px
  background-color: $lightBordo
  padding: 16px 30px
  +border-radius(999px)
  color: white
  display: flex
  gap: 16px
  align-items: center
  justify-content: center
  width: 100%

  &:hover
    cursor: pointer
    background-color: $hoverLightBordo

  &:active
    > *
      position: relative
      top: 1px

  img
    pointer-events: none
</style>
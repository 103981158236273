<template>
  <div class="flash-main">
    <flash-main-welcome />

    <flash-main-specialist id="specialist" />

    <flash-main-price id="price" />

    <flash-main-equipment id="equipment" />

    <flash-main-faq id="faq" />

    <flash-main-contacts id="contacts" />
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import flashMainWelcome from "@/components/templates/main/blocks/flash-main-welcome.vue";
import flashMainSpecialist from "@/components/templates/main/blocks/flash-main-specialist.vue";
import flashMainPrice from "@/components/templates/main/blocks/flash-main-price.vue";
import flashMainEquipment from "@/components/templates/main/blocks/flash-main-equipment.vue";
import flashMainFaq from "@/components/templates/main/blocks/flash-main-faq.vue";
import flashMainContacts from "@/components/templates/main/blocks/flash-main-contacts.vue";

export default defineComponent({
  name: "flash-main",

  components: {
    flashMainWelcome,
    flashMainSpecialist,
    flashMainPrice,
    flashMainEquipment,
    flashMainFaq,
    flashMainContacts,
  },
})
</script>

<style scoped lang="sass">
.flash-main
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  //background-color: pink

  @media (min-width: $desktopScreenMinWidth)

  @media (max-width: $mobileScreenMaxWidth)
    padding: 0 20px
</style>

<template>
  <div class="flash-main-specialist">
    <flash-box-shadow class="box-shadow" />

    <h5 class="title">СПЕЦИАЛИСТ</h5>

    <aside>
      <div class="left">
        <div class="image" />

        <div class="content only-ds">
          <div class="text">
            <h1>Я&nbsp;являюсь профессионалом
              в&nbsp;своей области и&nbsp;постоянно
              повышаю свою квалификацию, чтобы&nbsp;предоставлять
              клиентам только самые современные и&nbsp;эффективные
              методы лазерной эпиляции.</h1>

            <h1>Не&nbsp;стесняйтесь задавать&nbsp;вопросы
              или&nbsp;высказывать свои опасения&nbsp;— ваше
              удобство и&nbsp;удовлетворённость&nbsp;—
              моя&nbsp;главная цель.</h1>
          </div>

          <flash-button class="button" title="БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ" />
        </div>
      </div>

      <div class="right">
        <div class="info">
          <h1>Привет! Меня зовут Оксана,
            и&nbsp;я&nbsp;являюсь мастером
            по&nbsp;лазерной эпиляции в&nbsp;нашем
            салоне красоты. Я&nbsp;помогла многим
            клиентам избавиться от&nbsp;нежелательных волосков.</h1>

          <div class="statistics">
            <div class="statistic">
              <h4 class="top">5</h4>

              <h1 class="bottom">лет&nbsp;в&nbsp;лазерной эпиляции</h1>
            </div>

            <div class="statistic">
              <h4 class="top">27</h4>

              <h1 class="bottom">лет&nbsp;опыта в&nbsp;медицине</h1>
            </div>

            <div class="statistic">
              <h4 class="top">100+</h4>

              <h1 class="bottom">благодарных и&nbsp;довольных клиентов</h1>
            </div>
          </div>

          <h1>Мой опыт работы также позволяет мне
            предоставлять индивидуальный подход к&nbsp;каждому
            клиенту. Я&nbsp;понимаю, что у&nbsp;каждого
            человека свои особенности кожи и&nbsp;волос,
            поэтому я&nbsp;предоставляю персонализированные
            советы и&nbsp;рекомендации для&nbsp;достижения
            наилучших результатов.</h1>
        </div>

        <div class="image" />

        <div class="content only-mb">
          <div class="text">
            <h1>Я&nbsp;являюсь профессионалом
              в&nbsp;своей области и&nbsp;постоянно
              повышаю свою квалификацию, чтобы&nbsp;предоставлять
              клиентам только самые современные и&nbsp;эффективные
              методы лазерной эпиляции.</h1>

            <h1>Не&nbsp;стесняйтесь задавать&nbsp;вопросы
              или&nbsp;высказывать свои опасения&nbsp;— ваше
              удобство и&nbsp;удовлетворённость&nbsp;—
              моя&nbsp;главная цель.</h1>
          </div>

          <flash-button title="БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ" />
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import flashButton from "@/components/UI-kit/button/flash-button.vue";
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-specialist",

  components: {
    flashButton,
    flashBoxShadow,
  },
})
</script>

<style scoped lang="sass">
.flash-main-specialist
  display: flex
  flex-direction: column
  color: $deepBordo
  position: relative

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    width: 1216px
    margin-bottom: 160px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 30px
    align-items: center
    margin-bottom: 80px

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      left: -390px
      bottom: -260px

    @media (max-width: $mobileScreenMaxWidth)
      bottom: 50%
      left: -100px

  > .title
    font-weight: 400
    font-size: 48px
    line-height: 100%
    letter-spacing: 1.44px

  aside
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 80px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px
      flex-direction: column

    .left
      display: flex
      gap: 80px
      flex-direction: column
      align-items: flex-end

      .image
        +background-image-settings()
        +border-radius(12px)

        @media (min-width: $desktopScreenMinWidth)
          background-image: url("/src/assets/images/templates/main/specialist-left-ds.png")
          width: 392px
          height: 500px

        @media (max-width: $mobileScreenMaxWidth)
          background-image: url("/src/assets/images/templates/main/specialist-left-mb.png")
          width: 100%
          padding-top: calc(100% / 320 * 400)

      .content
        display: flex
        flex-direction: column
        gap: 40px

        .text
          display: flex
          flex-direction: column
          font-weight: 400
          font-size: 20px
          line-height: 140%
          gap: 10px

        .button
          @media (min-width: $desktopScreenMinWidth)
            position: relative
            left: -14px

          @media (max-width: $mobileScreenMaxWidth)

    .right
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 80px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 30px

      .info
        display: flex
        flex-direction: column
        font-weight: 400
        line-height: 140%

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px
          font-size: 20px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 30px
          font-size: 16px
          align-items: center

        .statistics
          display: flex

          @media (min-width: $desktopScreenMinWidth)
            gap: 40px
            justify-content: space-between

          @media (max-width: $mobileScreenMaxWidth)
            gap: 30px
            flex-direction: column
            max-width: 229px

          .statistic
            display: flex
            flex-direction: column
            gap: 16px
            text-align: center

            .top
              font-weight: 400
              font-size: 60px
              color: $lightBordo
              line-height: 100%

            .bottom
              font-size: 20px

      .image
        +background-image-settings()

        @media (min-width: $desktopScreenMinWidth)
          width: 744px
          height: 478px
          background-image: url("/src/assets/images/templates/main/specialist-right-ds.png")

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%
          padding-top: calc(100% / 320 * 400)
          background-image: url("/src/assets/images/templates/main/specialist-right-mb.png")

      .content
        display: flex
        flex-direction: column
        gap: 30px

        .text
          display: flex
          flex-direction: column
          padding-left: 14px
          font-weight: 400
          font-size: 20px
          line-height: 140%
          gap: 10px
</style>
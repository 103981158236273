<template>
  <div class="flash-main-price">
    <flash-box-shadow class="box-shadow" />

    <div class="title">
      <h5 class="name">ПРАЙС</h5>

      <div class="categories">
        <h1
            v-for="category in priceList"
            :key="`flash-main-price-categories-${ category['gender'] }`"

            :class="{
              'active': currentGender === category['gender']
            }"

            v-html="category['gender']"
            @click="currentGender = category['gender']"
        />
      </div>
    </div>

    <div
        class="price-wrapper"
        v-if="currentPriceList !== null"
    >
      <div
          class="category"
          v-for="category in currentPriceList.categories"
          :key="`flash-main-price-category-${ category.title }`"
      >
        <h1 class="title">{{ category.title }}</h1>

        <div class="services">
          <div
              class="service"
              v-for="service in category.services"
              :key="`flash-main-price-service-${ service.title }`"
          >
            <h1 class="title" v-html="service.title" />

            <h1 class="cost">{{ service.cost }}</h1>
          </div>
        </div>
      </div>

      <flash-button title="ЗАПИСАТЬСЯ" />
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import { priceList } from "@/components/templates/main/blocks/priceList";
import flashButton from "@/components/UI-kit/button/flash-button.vue";
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-price",

  components: {
    flashButton,
    flashBoxShadow,
  },

  data() {
    return {
      priceList,
      currentGender: priceList[0]['gender'],
    }
  },

  computed: {
    currentPriceList() {
      return priceList.find(price => price['gender'] === this.currentGender) || null
    },
  },
})
</script>

<!--@TODO Переделать везде '.title' на > '.title'-->
<style scoped lang="sass">
.flash-main-price
  display: flex
  flex-direction: column
  width: 100%
  position: relative

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    max-width: 1230px
    padding-right: 96px
    align-items: flex-start
    margin-bottom: 160px
    padding-left: 14px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 30px
    align-items: center
    margin-bottom: 80px

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      right: -260px
      bottom: 0

    @media (max-width: $mobileScreenMaxWidth)
      bottom: -200px
      left: -200px

  > .title
    display: flex
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 80px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px
      flex-direction: column

    .name
      font-weight: 400
      color: $deepBordo

      @media (min-width: $desktopScreenMinWidth)
        font-size: 48px
        line-height: 100%

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 30px
        line-height: 110%

    .categories
      gap: 30px
      display: flex
      align-items: baseline

      > *
        font-weight: 600
        font-size: 24px
        line-height: 140%
        color: $whiteBordo
        user-select: none
        transition: all .1s ease-in

        &::after
          margin-top: 8px
          content: ""
          display: block
          height: 2px
          width: 100%
          background-color: rgba(176, 70, 105, 0)
          transition: all .1s ease-in

        &:hover
          cursor: pointer

        &.active
          color: $deepBordo

          &::after
            background-color: $lightBordo

  .price-wrapper
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      column-count: 2
      column-gap: 123px

    @media (max-width: $mobileScreenMaxWidth)

    .category
      display: flex
      flex-direction: column
      width: 100%

      @media (min-width: $desktopScreenMinWidth)
        gap: 30px
        margin-bottom: 30px
        padding-bottom: 30px
        border-bottom: 1px solid $LightWhiteBordo
        page-break-inside: avoid
        break-inside: avoid

        &:last-of-type
          border-bottom: none

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px
        margin-bottom: 20px
        padding-bottom: 20px

        &:last-of-type
          margin-bottom: 30px

      > .title
        font-weight: 600
        font-size: 24px
        line-height: 140%
        color: $deepBordo

        @media (min-width: $desktopScreenMinWidth)

        @media (max-width: $mobileScreenMaxWidth)
          text-align: center

      .services
        display: flex
        flex-direction: column
        gap: 20px

        .service
          display: flex

          @media (min-width: $desktopScreenMinWidth)
            gap: 124px
            justify-content: space-between

          @media (max-width: $mobileScreenMaxWidth)

          > .title
            font-weight: 400
            font-size: 20px
            line-height: 140%
            color: $deepBordo

            @media (min-width: $desktopScreenMinWidth)

            @media (max-width: $mobileScreenMaxWidth)
              width: 100%

          .cost
            font-weight: 400
            font-size: 20px
            line-height: 140%
            color: $deepBordo

            @media (min-width: $desktopScreenMinWidth)

            @media (max-width: $mobileScreenMaxWidth)
              max-width: 143px
              width: 100%
              text-align: right
</style>
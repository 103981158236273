<template>
  <div class="flash-main-faq">
    <h5 class="title">Ответы на вопросы</h5>

    <main>
      <div
          class="question"
          v-for="(question, index) in faqList"
          :key="`flash-main-faq-questions-${ index }`"
      >
        <header>
          <h1 v-html="question.question" class="title" />

          <div class="close-button" @click="handleQuestionCloseButtonClick">
            <div class="line1" />
            <div class="line2" />
          </div>
        </header>

        <h1 class="text" v-html="question['answer']" />
      </div>
    </main>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {faqList} from "@/components/templates/main/blocks/faqList";

export default defineComponent({
  name: "flash-main-faq",

  data() {
    return {
      faqList,
    }
  },

  methods: {
    handleQuestionCloseButtonClick(e) {
      e.currentTarget.classList.toggle('active')
      e.currentTarget.parentElement.parentElement.classList.toggle('active')
    },
  },
})
</script>

<style scoped lang="sass">
.flash-main-faq
  display: flex
  flex-direction: column
  overflow: hidden

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    width: 1216px
    padding-right: 38px
    align-items: flex-start
    margin-bottom: 130px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    align-items: center
    width: 100%
    margin-bottom: 80px

  > .title
    font-weight: 400
    font-size: 48px
    letter-spacing: 1.44px
    color: $deepBordo

  main
    column-count: 2
    column-gap: 58px
    width: 100%
    position: relative

    @media (min-width: $desktopScreenMinWidth)
      column-count: 2
      top: 31px

    @media (max-width: $mobileScreenMaxWidth)
      column-count: 1
      top: 17px

    .question
      display: flex
      flex-direction: column
      page-break-inside: avoid
      break-inside: avoid
      border-bottom: 1px solid $LightWhiteBordo

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px
        padding-bottom: 30px
        margin-bottom: 30px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 16px
        padding-bottom: 16px
        margin-bottom: 16px

      &.active
        .text
          display: flex

      header
        font-weight: 600
        line-height: 140%
        display: flex
        justify-content: space-between
        gap: 30px
        align-items: flex-start

        @media (min-width: $desktopScreenMinWidth)
          font-size: 24px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 20px

        > .title
          flex: 1

          @media (min-width: $desktopScreenMinWidth)
            min-height: calc(2em * 1.4)

          @media (max-width: $mobileScreenMaxWidth)

        .close-button
          width: 30px
          height: 30px
          position: relative
          display: flex
          justify-content: center
          align-items: center

          &:hover
            cursor: pointer

            @media (min-width: $desktopScreenMinWidth)
              .line1
                transform: rotate(-10deg)

              .line2
                transform: rotate(80deg)

            @media (max-width: $mobileScreenMaxWidth)

          > div
            width: 30px
            height: 2px
            background-color: $deepBordo
            +border-radius(999px)
            position: absolute
            transition: all .1s ease-in

            &.line2
              transform: rotate(90deg)

          &.active
            &:hover
              cursor: pointer

              @media (min-width: $desktopScreenMinWidth)
                .line1
                  transform: rotate(-415deg)

                .line2
                  transform: rotate(-145deg)

              @media (max-width: $mobileScreenMaxWidth)

            @media (min-width: $desktopScreenMinWidth)
              .line1
                transform: rotate(-405deg)

              .line2
                transform: rotate(-135deg)

            @media (max-width: $mobileScreenMaxWidth)
              .line1
                transform: rotate(-45deg)

              .line2
                transform: rotate(45deg)

      .text
        display: none
        font-weight: 400
        line-height: 140%

        @media (min-width: $desktopScreenMinWidth)
          font-size: 18px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 16px
</style>
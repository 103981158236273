<template>
  <div class="flash-main-welcome">
    <flash-box-shadow  class="box-shadow"/>

    <div class="title">
      <h5>СТУДИЯ КОСМЕТОЛОГИИ И&nbsp;ЛАЗЕРНОЙ ЭПИЛЯЦИИ</h5>

      <h2 class="only-mb">в Пятигорске</h2>
    </div>

    <div class="content">
      <div class="image" />

      <div class="about">
        <h2 class="title only-ds">в Пятигорске</h2>

        <h1 class="text">Проводим процедуры на&nbsp;современном оборудовании, которое гарантирует эффективный результат и&nbsp;минимальный риск побочных эффектов</h1>

        <flash-button title="ЗАПИСАТЬСЯ" />
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import flashButton from "@/components/UI-kit/button/flash-button.vue";
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-welcome",

  components: {
    flashButton,
    flashBoxShadow,
  },
})
</script>

<style scoped lang="sass">
.flash-main-welcome
  display: flex
  flex-direction: column
  color: $deepBordo
  position: relative

  @media (min-width: $desktopScreenMinWidth)
    gap: 40px
    max-width: 1221px
    margin-bottom: 160px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 30px
    width: 100%
    margin-bottom: 80px

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      right: -260px
      bottom: 0

    @media (max-width: $mobileScreenMaxWidth)
      bottom: -90px
      right: -40px

  > .title
    display: flex
    flex-direction: column
    gap: 2px
    align-items: center

    > *:first-child
      font-weight: 400
      line-height: 120%
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 92px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 24px

    > *:last-child
      font-weight: 300
      font-size: 27px
      line-height: 100%

  .content
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 61px
      align-items: flex-end

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px
      flex-direction: column

    .image
      +background-image-settings()
      pointer-events: none

      @media (min-width: $desktopScreenMinWidth)
        width: 608px
        height: 325px
        background-image: url("/src/assets/images/templates/main/welcome-ds.png")
        +border-radius(12px)

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%
        padding-top: 100%
        background-image: url("/src/assets/images/templates/main/welcome-mb.png")
        +border-radius(4%)

    .about
      display: flex
      flex-direction: column
      flex: 1

      @media (min-width: $desktopScreenMinWidth)
        gap: 65px
        font-weight: 300
        font-size: 21px
        line-height: 140%

      @media (max-width: $mobileScreenMaxWidth)
        gap: 30px
        font-weight: 300
        font-size: 16px
        line-height: 140%

      > .title
        font-weight: 200
        font-size: 100px
        line-height: 100%

        @media (min-width: $desktopScreenMinWidth)
          margin-left: 9px

        @media (max-width: $mobileScreenMaxWidth)

      .text
        line-height: 140%

        @media (min-width: $desktopScreenMinWidth)
          margin-left: 9px

        @media (max-width: $mobileScreenMaxWidth)
          text-align: center
</style>
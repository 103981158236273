<template>
  <div class="flash-box-shadow">
    <div class="circle"></div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "flash-box-shadow",
})
</script>

<style scoped lang="sass">
.flash-box-shadow
  width: 260px
  height: 260px
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  z-index: -10

  .circle
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      width: 1100px
      height: 1100px
      background: radial-gradient(#DD054D19 20%, transparent 70%)

    @media (max-width: $mobileScreenMaxWidth)
      width: 1000px
      height: 1000px
      background: radial-gradient(rgba(221, 5, 77, 0.07) 20%, transparent 70%)
</style>
<template>
  <div class="flash-main-equipment">
    <flash-box-shadow class="box-shadow" />

    <h5 class="title">
      НА ЧЕМ МЫ РАБОТАЕМ
    </h5>

    <main>
      <img class="image only-ds" src="/assets/images/templates/main/equipment-ds.png" alt="">

      <div class="right">
        <div class="text">
          <h1>В&nbsp;своей работы мы&nbsp;используем
            современный диодный лазер Evolution от&nbsp;Prof
            Beauty, который имеет следующие преимущества:</h1>

          <ul class="advantages">
            <li><h1>Безопасность для&nbsp;кожи и&nbsp;минимальный риск осложнений</h1></li>
            <li><h1>Максимальный комфорт для&nbsp;клиента благодаря системе охлаждения</h1></li>
            <li><h1>Безболезненность процедуры и&nbsp;отсутствие реабилитации</h1></li>
            <li><h1>Высокая эффективность и&nbsp;точность процедуры</h1></li>
            <li><h1>Универсальность и&nbsp;возможность использования на&nbsp;различных типах кожи</h1></li>
          </ul>
        </div>

        <img class="image only-mb" src="/assets/images/templates/main/equipment-mb.png" alt="">

        <div class="text">
          <h1>Аппарат&nbsp;прошел сертификацию и&nbsp;полностью
            безопасен для&nbsp;проведения процедур.</h1>

          <h1>Ещё&nbsp;одним плюсом нашего аппарата
            является уникальная возможность разглаживать
            и&nbsp;омолаживать кожу в&nbsp;ходе процедуры!
            «Умный лазер» гарантирует быстрое удаление
            волос&nbsp;— результат заметён
            уже&nbsp;после&nbsp;первого сеанса!</h1>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-equipment",

  components: {
    flashBoxShadow,
  },
})
</script>

<style scoped lang="sass">
.flash-main-equipment
  display: flex
  flex-direction: column
  position: relative

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    width: 1216px
    align-items: flex-start
    margin-bottom: 160px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    align-items: center
    width: 100%
    margin-bottom: 80px

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      left: -390px
      bottom: -130px

    @media (max-width: $mobileScreenMaxWidth)
      display: none

  > .title
    font-weight: 400
    letter-spacing: 1.44px
    color: $deepBordo

    @media (min-width: $desktopScreenMinWidth)
      font-size: 48px
      line-height: 100%

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 30px
      line-height: 120%
      text-align: center

  main
    display: flex
    gap: 70px

    > .image
      width: 392px
      height: 500px
      +border-radius(12px)

    .right
      display: flex
      gap: 30px
      flex-direction: column
      flex: 1
      font-weight: 400
      font-size: 20px
      line-height: 140%
      color: $deepBordo

      .text
        gap: 16px
        display: flex
        flex-direction: column

        .advantages
          display: flex
          flex-direction: column
          gap: 12px

          > *
            display: flex
            align-items: baseline
            gap: 12px

            &::before
              content: ""
              display: inline-block
              background-color: $lightBordo
              +border-radius(50%)
              min-width: 12px
              height: 12px

      > .image
        width: 100%
        height: calc(100% / 320 * 400)
        +border-radius(12px)
</style>
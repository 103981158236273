<template>
  <div class="flash-burger" @click="$emit('click')">
    <div class="line" />
    <div class="line" />
    <div class="line" />
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "flash-burger",
})
</script>

<style scoped lang="sass">
.flash-burger
  display: flex
  flex-direction: column
  gap: 8px
  width: 38px
  padding: 1px
  +box-sizing(content-box)

  &:hover
    cursor: pointer

  .line
    width: 100%
    height: 2px
    background-color: black
    +border-radius(999px)
</style>